import React, { FC, HTMLAttributes, TdHTMLAttributes } from 'react'
import { ConfigProvider, SpinProps, Table, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { IOrderTable } from '../../models/IOrder'
import s from './TableOrders.module.scss'
import { OrderStatuses } from '../../configs/enums/orderStatuses'
import { OrderTypes } from '../../configs/enums/orderTypes'
import { transformDate } from '../../helpers/transformDate'
import { getNumberFromCodeName } from '../../helpers/getNumberFromCodeName'
import { ColorForStatus } from '../../configs/enums/colorForStatus'

const {Text} = Typography

const columns: ColumnsType<IOrderTable> = [
  {
    title: '№',
    dataIndex: 'code',
    width: 140,
    sorter: {
      compare: (a, b) => {
        return +getNumberFromCodeName(a.code) - +getNumberFromCodeName(b.code)
      },
    },
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Номер договора',
    dataIndex: 'contractNumber',
    width: 80,
    sorter: {
      compare: (a, b) => {
        return a.contractNumber - b.contractNumber
      },
    },
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Тип заказа',
    dataIndex: 'type',
    width: 230,
    showSorterTooltip: false,
    sortDirections: ['descend'],
    render: (text, row) => <>{OrderTypes[row?.type]}</>
  },
  {
    title: 'Клиент',
    dataIndex: ['contact'],
    width: 230,
    showSorterTooltip: false,
    sortDirections: ['descend'],
    render: (text, row) => <>{`${row.contact.secondName} ${row.contact.firstName} ${row.contact.middleName}`}</>
  },
  {
    title: 'Аванс, руб.',
    dataIndex: 'prepaymentSumm',
    sorter: {
      compare: (a, b) => +a.prepaymentSumm - +b.prepaymentSumm,
    },
    width: 190,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend'],
    // filterResetToDefaultFilteredValue: true
  },
  {
    title: 'Доплата, руб.',
    dataIndex: 'afterpaymentSumm',
    sorter: {
      compare: (a, b) => +a.afterpaymentSumm - +b.afterpaymentSumm,
    },
    width: 190,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend'],
    // filterResetToDefaultFilteredValue: true
  },

  {
    title: 'Сумма, руб.',
    dataIndex: 'price',
    sorter: {
      compare: (a, b) => +a.price - +b.price,
    },
    width: 170,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend'],
    // filterResetToDefaultFilteredValue: true
  },
  {
    title: 'Дата изготовления',
    dataIndex: 'createdAt',
    sorter: {
      compare: (a, b) => +new Date(b.deliveryDateTo) - +new Date(a.deliveryDateTo),
    },
    width: 180,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend'],
    render: (text, row) => (
      <>{`${transformDate(row.deliveryDateFrom)} - ${transformDate(row.deliveryDateTo)}` || ''}</>
    )
  },
  {
    title: 'Статус',
    dataIndex: 'status', //'status' OrderStatuses[status.value]],
    sorter: {
      compare: (a, b) => a.productOrderStatus.ordering - b.productOrderStatus.ordering,
    },
    width: 200,
    showSorterTooltip: false,
    sortDirections: ['ascend', 'descend'],
    render: (text, row) => (
      <Text style={{color: ColorForStatus[row?.productOrderStatus?.value], fontWeight: 500}}>
        {OrderStatuses[row?.productOrderStatus?.value]}
      </Text>
    )
  }
]

interface IProps {
  ordersData: IOrderTable[]
  onRow: (record: IOrderTable) => HTMLAttributes<any> | TdHTMLAttributes<any>
  loading?: boolean | SpinProps
}

const TableOrders: FC<IProps> = ({ordersData, onRow, loading = false}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            padding: 10
          },
        },
      }}
    >
      <Table className={s.table}
             columns={columns}
             dataSource={
               ordersData?.map(item => ({...item, key: item.id}))
             }
             pagination={false}
             onRow={onRow}
             loading={loading}
             scroll={{x: 900}}
             bordered
      />
    </ConfigProvider>
  )
}

export default TableOrders